import GamPosition from './gam_position'
import { debounce } from '../../../../lib/utils/timing'
import { dispatchCustomEvent } from '../../../../lib/utils/events'

const DEFAULT_GAM_CONFIG = {
  setting: {},
  event: {},
  positions: {}
}
const GAM_CONFIG_NAMESPACE = '__GAM_CONFIG'

export default (() => {
  class GAM {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      const hasDlPoc = document.querySelector('.dl--gamAds')
      const hasTopStoriesPoc = document.querySelector('.m-top-stories--gamAds')
      if (!this.elems.length && !hasDlPoc && !hasTopStoriesPoc) {
        return
      }
      this.positions = this.elems.map(elem => GamPosition.fromElement(elem)).filter(Boolean)
      this._init()
    }

    // Public Methods
    createGamPlacement(elem) {
      const placement = GamPosition.fromElement(elem)
      if (!placement) return console.error('[GAM] error creating placement')
      this.positions.push(placement)
      return placement
    }

    // Private Methods
    _init() {
      const globalConfig = this._getGlobalGamConfig()
      this.config = {
        ...globalConfig,
        positions: {
          ...(globalConfig.positions || {}),
          ...this._getFormattedIndexPositionConfig()
        }
      }
      this._startBenji()
      this._addEventListeners()
      this._addPublicInterface()
      dispatchCustomEvent('GAM:inited', {})
    }

    _startBenji() {
      if (window.benji) {
        window.benji.start(this.config)
      } else {
        window.benji = { config: this.config }
      }
    }

    _getGlobalGamConfig() {
      return { ...DEFAULT_GAM_CONFIG, ...(window[GAM_CONFIG_NAMESPACE] || DEFAULT_GAM_CONFIG) }
    }

    _getFormattedIndexPositionConfig() {
      return this.positions.reduce((acc, position) => {
        if (position.region !== 'index') {
          return acc
        }
        if (position.hasRoomToRender()) {
          acc[position.id] = position.config
          position.setCreatedStatus()
        } else {
          position.destroyPlaceholder()
        }
        return acc
      }, {})
    }

    _handleWindowResize() {
      this.positions.forEach(position => {
        position.handleWindowResize()
      })
    }

    _handleWindowScroll() {
      this.positions.forEach(position => {
        position.handleWindowScroll()
      })
    }

    _handleAdBlockEnabled() {
      // TODO: uncomment when Benji supports updating abk value
      // window.benji?.updateI13N?.({ abk })
      // window.benji?.refresh?.()
    }

    _addEventListeners() {
      document.addEventListener('GAM:refresh', ({ detail: adsToRefresh = [] }) => {
        window.benji?.refresh(adsToRefresh)
      })
      document.addEventListener('GAM:enableAdBlock', this._handleAdBlockEnabled.bind(this))

      window.addEventListener('resize', debounce(this._handleWindowResize.bind(this), 200))
      window.addEventListener('scroll', debounce(this._handleWindowScroll.bind(this), 100))
      this._handleWindowScroll()

      window.benji?.gpt?.addEventListener('slotRenderEnded', event => {
        // Event Object: https://developers.google.com/publisher-tag/reference#googletag.events.slotrenderendedevent
        this.positions.forEach(position => {
          if (event?.slot?.getSlotElementId() === position.id) {
            position.onRender(event)
          }
        })
      })
      window.benji?.gpt?.addEventListener('slotRequested', event => {
        // Event Object: https://developers.google.com/publisher-tag/reference#googletag.events.slotrequestedevent
        this.positions.forEach(position => {
          if (event?.slot?.getSlotElementId() === position.id) {
            position.onRequested(event)
          }
        })
      })
    }

    _addPublicInterface() {
      window.GAM = {
        createPlacement: this.createGamPlacement.bind(this)
      }
    }
  }

  return GAM
})()
